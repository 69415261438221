import { ReferenceType } from '../_enums/referenceType';

export class Filter {
    referenceType: ReferenceType;
    id: string;
    displayText: string = null;
    parentId: string = null;
    thumbnailUrl: string;
    itemCount: number = null;
    children: Filter[];
    checked: boolean;
    indented: boolean;
    automatic: boolean;
    isEditing: boolean;
    isAdding: boolean;
    automaticOverridden: boolean;
    directCount: number = null;
    type: string;

    constructor(id: string, referenceType: ReferenceType) {
        this.id = id;
        this.referenceType = referenceType;
        this.displayText = '';
        this.parentId = null;
        this.thumbnailUrl = '';
        this.itemCount = null;
        this.children = [];
        this.checked = false;
        this.indented = false;
        this.automatic = false;
        this.isEditing = false;
        this.isAdding = false;
        this.automaticOverridden = false;
        this.directCount = null;
    }
}

export function cloneFilter(filter: Filter) {
    let f: Filter = new Filter(filter.id, filter.referenceType);
    f.displayText = filter.displayText;
    f.parentId = filter.parentId;
    f.thumbnailUrl = filter.thumbnailUrl;
    f.itemCount = filter.itemCount;
    f.children = [];
    f.checked = filter.checked;
    f.indented = filter.indented;
    f.automatic = filter.automatic;
    f.isEditing = filter.isEditing;
    f.isAdding = filter.isAdding;
    f.automaticOverridden = filter.automaticOverridden;
    f.directCount = filter.directCount;

    filter.children.forEach((fltr: Filter) => {
        f.children.push(cloneFilter(fltr));
    });

    return f;
}
