<div id="reports-viewport">
  <!-- Reports header -->
  <div id="reports-header">
    <div class="col-md-12 page-title-box internal-page-title align-middle reports-breadcrumb">
      <!-- Breadcrumb -->
      <span><a title="Catalog" (click)="router.navigateByUrl('/home/catalog/view/home')">Catalog</a></span>
      <span><a title="Reports" (click)="router.navigateByUrl('/home/catalog/reports')">Reports</a></span>
    </div>
  </div>
  <div class="padded">
    <div class="grid-view-pane">
      <div class="grid-document">
        <div class="wayfinder-report clickable" (click)="showMenu($event)"><fa-icon [icon]="['fas', 'ellipsis']" size="s"></fa-icon></div>
        <div class="grid-thumbnail"><div><img src="/assets/images/servicesmatrix.png" /></div></div>
        <div class="grid-document-footer non-reference-type">
          <div class="caption center">Room Data Master</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="reports-popup" class="{{ showingPopupMenu ? '' : 'hide' }}"
     style="top: {{ popupMenuY }}px;left: {{ popupMenuX }}px;">
  <ul>
    <li>
      <a (click)="requestServicesMatrix()">Export</a>
    </li>
  </ul>
</div>
