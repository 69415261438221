//angular
import { Component } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent  {
    navbarOpen: boolean = true;
 
    constructor() { }

    toggleNavbar($event) {
        this.navbarOpen = $event;
    }

}
