//angular imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

//local imports
import { ReferenceType } from '../_enums/referenceType';
import { AppConfig } from '../app-config';
import { WayFinderProperty } from '../_interfaces/wayFinderProperty';

@Injectable({
  providedIn: 'root'
})

export class PropertyService {
  constructor(private http: HttpClient, private applicationConfig: AppConfig) {    
  }

  getProperties(roomId: string, departmentId: string | null, projectId: string | null): Observable<WayFinderProperty[]> {
    let url: string = `${this.applicationConfig.applicationUri}/v2/property?roomId=${roomId}&departmentId=${departmentId}`;
    if (projectId != null) url = url + `&projectId=${projectId}`;
    return this.http.get<WayFinderProperty[]>(url);
  }
}
