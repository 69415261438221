export class PropertyCategory {
    id: string;
    name: string;
    thumbnailUrl: string;
    properties: Property[];
    propertyIndices: number[];
    index: number;

    constructor(Id: string, Name: string, ThumbnailUrl: string) {
        this.id = Id;
        this.name = Name;
        this.thumbnailUrl = ThumbnailUrl;
        this.properties = [];
        this.propertyIndices = [];
        this.index = 0;
    }

    addProperty(property: Property) {
        this.propertyIndices[property.name] = this.index++;
        this.properties.push(property);
    }

    getProperty(name: string) {
        if (this.propertyIndices[name] == undefined) return undefined;
        return this.properties[this.propertyIndices[name]];
    }
}

export class Property {
    id: string;
    name: string;
    values: PropertyValue[]
    distinctValues: boolean[];

    constructor(Id: string, Name: string) {
        this.id = Id;
        this.name = Name;
        this.values = [];
        this.distinctValues = [];
    }

    addValue(value: PropertyValue) {
        if (this.distinctValues[value.name] === undefined) {
            this.values.push(value);
            this.distinctValues[value.name] = true;
        }
    }
}

export class PropertyValue {
    id: string;
    name: string;
    isOverridden: boolean;
    constructor(Id: string, Name: string, IsOverridden: boolean) {
        this.id = Id;
        this.name = Name;
        if (this.name.endsWith('.00')) this.name = this.name.substring(0, this.name.length - 3);
        this.isOverridden = IsOverridden;
    }
}
