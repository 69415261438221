//angular imports
import { Component, OnInit, Output, EventEmitter, OnDestroy, Signal, computed, signal } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { toSignal } from '@angular/core/rxjs-interop';
import { Subscription } from 'rxjs';

//msal imports
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';

//local imports
import { UserPermissionsService } from '../../_services/user-permissions.service';
import { PageReloadService } from '../../_services/page-reload-service';
import { UserNotificationService } from 'src/app/_services/notification.service';
import { GroupByService } from '../../_services/groupBy.service';
import { LibraryNavigationService } from '../../_services/libraryNavigationService';
import { expandCamelCase } from '../../_helpers/camelCaseHelper';
import { ReferenceType } from '../../_enums/referenceType';
import { AppConfig } from 'src/app/app-config';
import { navigationIcons } from '../../_models/navigationIcons';
import { Rights } from 'src/app/_enums/right';
import { Persona, formatPersona } from '../../_enums/persona';
import { PersonaService } from '../../_services/persona.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})

export class NavigationComponent implements OnInit, OnDestroy {
  public formatPersona = formatPersona;
  currentUser: AccountInfo;
  isDevelopmentStage: boolean = false;

  submitted = false;
  loading = false;

  popupWindowTitle: string;
  popupWindowOpen = false;

  @Output() IsnavbarOpen = new EventEmitter<boolean>();
  navbarOpen = true;

  reportsSubMenu: NavigationSubMenu[] = [];
  librarySubMenu = signal<NavigationSubMenu[]>([]);
  catalogSubMenu = signal<NavigationSubMenu[]>([]);

  inLibrary: boolean = false;
  groupBySubsciption: any;
  rights: Signal<Rights[]>;
  private personaServiceInitializedSubscription: Subscription;
  private personaChangedSubscription: Subscription;

  public canViewCatalogue = computed(() => {
    let rights = this.rights();
    
    return rights && rights.some(i=>i == Rights.View_Catalog || i == Rights.Configure_Catalog);
  });

  public canViewLibrary = computed(() => {
    let rights = this.rights();
        
    return rights && rights.some(i=>i == Rights.View_Library || Rights.Edit_Library || Rights.Add_Library || Rights.Delete_Library || Rights.Configure_Library);
  });

  public canViewSpacebot = computed(() => {
    let rights = this.rights();

    if (rights == null) return false;
    
    return rights && rights.some(i=>i == Rights.View_SpaceBot);
  });

  constructor(
    private authenticationService: MsalService,
    private notificationService: UserNotificationService,
    private router: Router,
    private groupByService: GroupByService,
    private pageReloadService: PageReloadService,
    public libraryNavigationService: LibraryNavigationService,
    private appConfig: AppConfig,
    private dialog: MatDialog,
    private userPermissionsService: UserPermissionsService,
    public personaService: PersonaService,
  ) {    
    console.log(this.authenticationService.instance.getActiveAccount());

    //Convert broadcast to a signal
    this.rights = toSignal(this.userPermissionsService.rights);

    this.personaServiceInitializedSubscription = this.personaService.initialized().subscribe(loaded => {
      if (loaded) {
        this.groupBySubsciption = this.groupByService.groupBysUpdated.subscribe(_ => {
          this.loadLibrarySubMenu();
          this.loadCatalogSubMenu();
        });
      }
    });

    
    this.personaChangedSubscription = this.personaService.changed().subscribe(_ => {
      this.loadLibrarySubMenu();
      this.loadCatalogSubMenu();
    });

    this.inLibrary = this.router.url.indexOf('home/documents') > -1;
  }

  public personaSelected(persona: Persona) {
    this.personaService.setPersona(persona);
  }


  public loadLibrarySubMenu() {
    if (this.router.url.indexOf('home/documents') > -1) {
      let newNavigationSubMenu: NavigationSubMenu[] = [];

      newNavigationSubMenu.push(new NavigationSubMenu('Recent', 'recent', 'clock'));
      newNavigationSubMenu.push(new NavigationSubMenu('Starred', 'starred', 'star'));

      this.groupByService
        .getTopLevelGroupBysForPersonaInLibrary(this.personaService.currentPersona())
        .subscribe({
            next: groupBys => {
              for (let i = 0; i < groupBys.length; i++) {
                newNavigationSubMenu.push(new NavigationSubMenu(expandCamelCase(ReferenceType[groupBys[i].referenceType]), 'wayfinder/' + groupBys[i].referenceType, navigationIcons[groupBys[i].referenceType]));
              }

              newNavigationSubMenu.push(new NavigationSubMenu('Downloads', 'downloads/', 'file-arrow-down'));

              let change = false;
              
              if (this.librarySubMenu().length != newNavigationSubMenu.length) 
              {
                change = true;
              }
              else 
              {
                for (let i = 0; i < this.librarySubMenu().length; i++) 
                {
                  if (this.librarySubMenu()[i].Name !== newNavigationSubMenu[i].Name) 
                  {
                    change = true;
                    break;
                  }
                }
              }
              
              if (change) this.librarySubMenu.set(newNavigationSubMenu);
            },
            error: error => {
              this.notificationService.showNotification(`An unexpected error occurrred. ${error?.error ? error.error : ''}`, 'error');
            }
          });

    } else this.librarySubMenu.set([]);
  }

  public loadCatalogSubMenu() {
    if (this.router.url.indexOf('home/catalog') > -1) {
      let newNavigationSubMenu: NavigationSubMenu[] = [];
      let reports: NavigationSubMenu = new NavigationSubMenu('Reports', 'reports', 'file-contract');
      //      if (this.router.url.indexOf('reports') > -1) {
      //        reports.children.push(new NavigationSubMenu('Room Data Sheet', 'reports/roomdata', 'file-contract'));
      //        reports.children.push(new NavigationSubMenu('Services Matrix', 'reports/servicesmatrix', 'file-contract'));
      //      }
      newNavigationSubMenu.push(reports);

      this.groupByService
        .getTopLevelGroupBysForPersonaInCatalog(this.personaService.currentPersona())
        .subscribe({
            next: groupBys => {
              for (let i = 0; i < groupBys.length; i++) {
                newNavigationSubMenu[newNavigationSubMenu.length] = new NavigationSubMenu(expandCamelCase(ReferenceType[groupBys[i].referenceType]), 'view/wayfinder/' + groupBys[i].referenceType, navigationIcons[groupBys[i].referenceType]);
              }
              let change: boolean = false;
              if (this.catalogSubMenu().length > 0 && this.catalogSubMenu()[0].children.length != reports.children.length) change = true;
              if (this.catalogSubMenu().length != newNavigationSubMenu.length) change = true;
              else for (let i = 0; i < this.catalogSubMenu().length; i++) if (this.catalogSubMenu()[i].Name !== newNavigationSubMenu[i].Name) change = true;
              if (change) this.catalogSubMenu.set(newNavigationSubMenu);            },
            error: error => {
              this.notificationService.showNotification(`An unexpected error occurrred. ${error?.error ? error.error : ''}`, 'error');
            }
          });
    } else this.catalogSubMenu.set([]);
  }

  onPageReload() {
    this.loadLibrarySubMenu();
    this.loadCatalogSubMenu();
    this.inLibrary = this.router.url.indexOf('home/documents') > -1;
  }

  ngOnInit() {
    if (this.appConfig.stage == "dev" || this.appConfig.stage == "testing") {
      this.isDevelopmentStage = true;
    }
    this.pageReloadService.subscribeReloadMethod(this);
    this.currentUser = this.authenticationService.instance.getActiveAccount();   
    this.userPermissionsService.initialize();
  }


  ngAfterViewInit() {
  }

  logout() {
    this.authenticationService.logoutRedirect();
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
    this.IsnavbarOpen.emit(this.navbarOpen);
  }
  

  ngOnDestroy() {
    this.pageReloadService.unsubscribeReloadMethod();
    this.personaServiceInitializedSubscription.unsubscribe();
    this.personaChangedSubscription.unsubscribe();
  }
}

class NavigationSubMenu {
  children: NavigationSubMenu[] = [];
  constructor(
    public Name: string,
    public Route: string,
    public IconCode: string
  ) { }
}

