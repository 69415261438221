import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { PageReloadService } from 'src/app/_services/page-reload-service';
import { SafePipe } from '../_helpers/safePipe';
import { ReportService } from '../_services/report.service';
import { IReportEmbedConfiguration, models, service, Embed } from 'powerbi-client';
import { ReferenceType } from '../_enums/referenceType';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';
import { PowerBiReport } from '../_interfaces/powerBiReport';
import { Breadcrumb } from '../_interfaces/breadcrumb';
import { WayFinderTile } from '../_models/wayfinderTiles';
import { AppConfig } from '../app-config';
import { BehaviorSubject, Subject, Subscription, debounceTime } from 'rxjs';
import { UserNotificationService } from '../_services/notification.service';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.css'],
    providers: [SafePipe]
})

export class ReportsComponent implements OnInit, OnDestroy {
    reportsTiles: WayFinderTile[] = [];

    private downloadSubscription: Subscription;

    private onClick: (event: MouseEvent) => void;
    popupMenuX: number = 0;
    popupMenuY: number = 0;
    showingPopupMenu: boolean = false;
    popupEvents: MouseEvent[];

  constructor(
    private reportService: ReportService,
    public router: Router,
    private notificationService: UserNotificationService,
  ) {
    this.popupEvents = [];
    this.onClick = (event: MouseEvent) => {
      let found: boolean = false;
      this.popupEvents.forEach((evt: MouseEvent) => { if (evt == event) found = true; });
      if (!found) this.showingPopupMenu = false;
    };
  }

  showMenu(evt: MouseEvent) {
    this.popupEvents = [];
    this.popupEvents.push(evt);
    this.showingPopupMenu = true;
    this.popupMenuX = evt.clientX;
    this.popupMenuY = evt.clientY;
  }

  requestServicesMatrix() {
    this.reportService.requestServicesMatrix(null);
    this.notificationService.showNotification(`Processing request - please await email`, 'success');
  }

  ngOnInit(): void {
    document.addEventListener('click', this.onClick);
  }

  ngOnDestroy() {
    window.removeEventListener('click', this.onClick);
    if (this.downloadSubscription !== undefined) this.downloadSubscription.unsubscribe();
  }
}
