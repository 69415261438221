<!-- <div id="unity-container" class="unity-desktop"> -->  
<div id="unity-container">
  
  <div id="unity-overlay-container">
    <div id="unity-canvas-placeholder" tabindex="-1">
      <div id="unity-overlay" *ngIf="isLoading">
        <img src="/assets/images/loading.gif" width="50" height="50" />
      </div>      
    </div>
  </div>
    
  
  <div id="unity-loading-bar">
    <div id="unity-logo"></div>
    <div id="unity-progress-bar-empty">
      <div id="unity-progress-bar-full"></div>
    </div>
  </div>
  <div id="unity-warning"> </div>
  <div id="unity-footer">
    <div id="unity-webgl-logo" style="visibility: hidden;"></div>
    <div id="unity-fullscreen-button"></div>
    <div id="unity-build-title" style="visibility: hidden;"></div>
  </div>
</div>