import { Injectable } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationService {

  constructor(private notificationService: NotificationService) { }

  public showNotification(content: any, type, closable = false): void {
    this.notificationService.show({
      content: content,
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 500 },
      closable: closable,
      hideAfter: type == 'error' ? 4000 : 2000,
      type: { style: type, icon: true }
    });
  }
}
