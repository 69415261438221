export enum Rights {
    None = 0,

    //View
    View_Library = 1,        
    View_Catalog = 2,
    View_SpaceBot = 5,

    //Create/Edit
    Edit_Library = 3,
    Add_Library = 4,    

    //Configure
    Configure_Library = 6,
    Configure_Catalog = 7,

    //Delete
    Delete_Library = 8

}

export class RightHelper {
    public static getRights(): Rights[] {
        return Object.keys(Rights)
            .filter((key, i) => !isNaN(Number(key)))
            .map((key) => Rights[key]);
    }
}
