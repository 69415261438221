

//AppConfig.d.ts
export class AppConfig {
    applicationUri: string;
    appId: string;
    appName: string;
    stage: string;
    prefix: string;
    azureB2c: {
        instance: string;
        domain: string;
        signUpSignIn: string;
        resetPassword: string;
        editProfile: string;
    }
}






