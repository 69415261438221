import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnityComponent } from './unity.component';

@NgModule({
    imports: [
        CommonModule
    ],
    exports:[
        UnityComponent
    ],
    declarations: [UnityComponent]    
})
export class UnityModule { }