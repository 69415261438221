//angular
import { Injectable } from '@angular/core';

//rxjs
import { ReplaySubject } from 'rxjs';

//local
import { Rights } from '../_enums/right';
import { UserService } from './user.service';


@Injectable({
    providedIn: 'root'
  })
export class UserPermissionsService 
{
    public rights: ReplaySubject<Rights[]> = new ReplaySubject<Rights[]>(null);

    constructor(
      private userService: UserService) {    
    }

    public initialize(): void {
        this.userService.getPermissions().subscribe(permissions => {
            this.rights.next(permissions);
        });
    }    
}