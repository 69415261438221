//amgular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//rxjs
import { Observable, Subject, defer, from, of } from 'rxjs';

//local

import { UserSettings } from '../_models/userSettings';
import { AppConfig } from '../app-config';
import { Rights } from '../_enums/right';

export class User {
  id: string;
  userName: string;
  password: string;
  firstName: string;
  lastName: string;
  fullName: string;
  token?: string;
  isActive: boolean;  
  createdDate: string;
  token_expiry_time: string;
  authdata?: string;
  companyName: string;
  avatarInitials: string;
  avatarColor: string;
  isSuperUser: boolean;
}


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private applicationConfig: AppConfig) {    
  }
  getPermissions(): Observable<Rights[]> {    
    return this.http.get<Rights[]>(`${this.applicationConfig.applicationUri}/v2/user/permission`);
  }
}