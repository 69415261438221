import { WayFinderProperty } from '../_interfaces/wayFinderProperty';
import { Property, PropertyCategory, PropertyValue } from '../_interfaces/properties';
import { Filter } from '../_models/filter';


export function aggregateProperties(properties: WayFinderProperty[], cats: Filter[] | null, roomContents: Filter[] | null, roomContentsQuantities: number[] | null) {
    let categories: PropertyCategory[] = [];
    let categoryIndices: number[] = [];
    let ind = 0;
    properties.forEach((prop: WayFinderProperty, index: number) => {
      if (prop.categoryName != null) {
        let name = prop.categoryName.toLowerCase();
        if (name.indexOf('space') == -1 && name.indexOf('program') == -1) {
          if (categoryIndices[prop.categoryName] === undefined) {
            categoryIndices[prop.categoryName] = ind++;
            let cat: PropertyCategory = new PropertyCategory(prop.categoryId, prop.categoryName, prop.categoryThumbnail);
            categories.push(cat)
          }
          let category = categories[categoryIndices[prop.categoryName]];
          let property: Property = category.getProperty(prop.propertyName);
          if (property == undefined) {
            property = new Property(prop.propertyId, prop.propertyName);
            category.addProperty(property);
          }
          let value: PropertyValue = new PropertyValue(prop.propertyValueId, prop.propertyValueText, prop.isOverridden);
          property.addValue(value);
        }
      }
    });

    if (cats != null) {
      // adding room content to properties for select persona
      roomContents.forEach((rc: Filter, index: number) => {
        if (categoryIndices[rc.type] === undefined) {
          let c = cats.find(x => x.displayText == rc.type);
          if (c != undefined) {
            categoryIndices[rc.type] = ind++;
            let cat: PropertyCategory = new PropertyCategory(c.id, c.displayText, c.thumbnailUrl);
            categories.push(cat)
          }
        }
     
        let category = categories[categoryIndices[rc.type]];
        if (category !== undefined) {
          let property: Property = new Property(rc.id, rc.displayText);
          let qty = roomContentsQuantities[rc.id];
          if (qty === undefined) qty = 0;
          let value: PropertyValue = new PropertyValue(rc.id, '' + qty, false);
          property.addValue(value);
          category.addProperty(property);
        }
      });
    }

    categories.sort((a, b) => a.name.localeCompare(b.name));
    return categories;
}