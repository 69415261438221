import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

// Previously when the same URL is routed to (i.e. you are already on the Documents page 
// and you click on the "Documents" button on the left navigation bar) nothing would happen.
// This service subcribes a reload method that will be executed when the same URL is routed to.
// The reload method on the page needs to be called "onPageReload()". The page itself needs to 
// be passed to the subrcribe method so that when the method is executed, it has access to the 
// local variables and functions.
export class PageReloadService {
  navigationSubscription;

  constructor(private router: Router) { }

  subscribeReloadMethod(page) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        page.onPageReload();
      }
    });
  }

  unsubscribeReloadMethod() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}