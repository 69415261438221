//angular
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//msal
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';

//local
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NewSpaceConfiguratorComponent } from './newspace-configurator/newspace-configurator.component';
import { ReportsComponent } from './reports/reports.component';
import { WelcomeComponent } from './welcome/welcome.component';

const routes: Routes = [

  { path: '', redirectTo: '/home/welcome', pathMatch: 'full' },  
  {
    path: 'home', 
    component: HomeComponent, 
    canActivate: [MsalGuard], 
    children: [
      { path: 'documents', loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsModule), canActivate: [MsalGuard]},
      { path: 'catalog', loadChildren: () => import('./catalog/catalog.module').then(m => m.CatalogModule), canActivate: [MsalGuard] },

      //TODO:DIW:Move these to their own module/child routes.
      { path: 'welcome', component: WelcomeComponent, canActivate: [MsalGuard] },
      { path: 'spaceconfigurator', component: NewSpaceConfiguratorComponent, canActivate: [MsalGuard] },
      { path: 'catalog/reports', component: ReportsComponent, canActivate: [MsalGuard] },
      { path: 'catalog/reports/:reportName', component: ReportsComponent, canActivate: [MsalGuard] },
     ]
  },
  {
    // Needed for handling redirect after login
    path: 'auth',
    component: MsalRedirectComponent
  },

  //otherwise redirect to login
  { path: '**', component: PageNotFoundComponent }

];


@NgModule({
  imports: [RouterModule.forRoot(routes, { 
    onSameUrlNavigation: 'reload',
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

