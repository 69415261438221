//angular imports
import { Component, Signal, computed, signal } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';

//local imports
import { WayFinderTile } from '../_models/wayfinderTiles';
import { UserPermissionsService } from '../_services/user-permissions.service';
import { Rights } from '../_enums/right';

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent {

    public rights: Signal<Rights[]>;

    public canViewCatalogue = computed(() => {
        let rights = this.rights();

        return rights && rights.some(i => i == Rights.View_Catalog || i == Rights.Configure_Catalog);
    });

    public canViewLibrary = computed(() => {
        let rights = this.rights();

        return rights && rights.some(i => i == Rights.View_Library || Rights.Edit_Library || Rights.Add_Library || Rights.Delete_Library || Rights.Configure_Library);
    });

    public tiles = computed<WayFinderTile[]>(() => {
        let result = [];

        if (this.canViewCatalogue()) {
            let tile = new WayFinderTile();

            tile.name = 'Catalog';
            tile.icon = 'archive';
            tile.url = '/home/catalog/view/home';

            result.push(tile);
        }

        if (this.canViewLibrary()) {
            let tile = new WayFinderTile();

            tile.name = 'Library';
            tile.icon = 'file';
            tile.url = '/home/documents/view/home';

            result.push(tile);
        }

        return result;
    });

    constructor(
        public router: Router,
        private userPermissionService: UserPermissionsService
    ) {
        this.rights = toSignal(this.userPermissionService.rights);
    }
}

