/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { AppConfig } from './app/app-config';
import { environment } from './environments/environment';

// main.ts

if (environment.production) {
  enableProdMode();
}

fetch('assets/app.config.json')
  .then(response => response.json())
  .then(config => {
    platformBrowserDynamic(
      [
        {
          provide: AppConfig,
          useValue: config
        }
      ])
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));
  });

