// A service that wraps SignalR HubConnection and uses polly-js to handle connection failures and reconnections
import { WritableSignal, signal } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

import polly from 'polly-js';

/**
 * @summary An enum for the SignalR connection state
 */
export enum SignalRConnectionState {
  Disconnected = 0,
  Connecting = 1,
  Connected = 2
}

export type SignalrMessageHandler = (...args: any[]) => void;

/**
 * @summary A service that wraps SignalR HubConnection and uses polly-js to handle connection failures and reconnections
 */
export class SignalRConnection {
  private hubConnection?: HubConnection | null;
  private method?: string;
  private handler?: SignalrMessageHandler;

  // Add a property to store the current state of the connection as a writable signal
  private connectionState: WritableSignal<SignalRConnectionState> = signal(SignalRConnectionState.Disconnected);

  /**
   * @summary Start the hub connection and handle errors     
   * @returns a promise, which resolves when the connection is complete
   */
  public async connect(
    hubUrl: string,
    getAccessToken: () => Promise<string>,
    method: string,
    handler: SignalrMessageHandler): Promise<void> {
    try {


      //Calling connect again is allowed to support updating the access token.
      if (this.hubConnection) return

      this.method = method;
      this.handler = handler;



      // .withUrl(hubUrl, 
      //     {
      //         headers: () => {                        
      //             "Authorization": `Bearer ${this.accessToken!}`                        
      //         }
      //         //accessTokenFactory: () => this.accessToken! 
      //     })

      // Create a new hub connection with the desired url
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(hubUrl, {
          accessTokenFactory: getAccessToken,
          // headers: 
          // { 
          //     "Authorization": `Bearer ${this.accessToken!}` 
          // },                                        
          withCredentials: false,
          transport: HttpTransportType.LongPolling
        })
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: retryContext => {
            // Get the next retry delay
            return 5000;  //TOOD:DIW:Retry every n seconds, allow configuration
          }
        })
        .configureLogging(LogLevel.Information) //TODO:DIW:Allow configuration
        .build();

      // Register a handler for the onclose event to update the connection state
      this.hubConnection.onclose(() => {
        this.connectionState.set(SignalRConnectionState.Disconnected);
      });
      // Register a handler for the onreconnecting event to update the connection state
      this.hubConnection.onreconnecting(() => {
        this.connectionState.set(SignalRConnectionState.Connecting);
      });
      // Register a handler for the onreconnected event to update the connection state
      this.hubConnection.onreconnected(() => {
        this.connectionState.set(SignalRConnectionState.Connected);
      });

      this.hubConnection.on(this.method!, this.handler!);

      // Use polly-js to retry the start request if it fails
      await polly()
        .handle((err) => err instanceof Error)
        .waitAndRetry(15) //TODO:DIW:Allow config
        .executeForPromise(async () => {
          // Start the hub connection
          try {
            await this.hubConnection!.start();
            console.log('Hub connection started');
          }
          catch (error) {

          }
        });
    } catch (err) {
      // Handle the connection error
      console.error('Error while starting connection: ' + err);
    }
  }

  /**
   * @summary Stop the hub connection
   * @returns a promise, which resolves when the connection is complete
   */

  public async disconnect(): Promise<void> {
    try {

      if (this.hubConnection) {

        this.hubConnection.off(this.method!, this.handler!);

        this.hubConnection.stop();

        await this.hubConnection.stop();

        console.log('Hub connection stopped');

        this.hubConnection = undefined;
      }
    } catch (err) {

      console.error('Error while stopping connection: ' + err);
    }
  }
}
