//angular
import { Platform } from "@angular/cdk/platform";

//Msal
import { BrowserCacheLocation, LogLevel, ProtocolMode, IPublicClientApplication, PublicClientApplication, InteractionType, Configuration } from '@azure/msal-browser';

import {
  MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService,
  MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent, ProtectedResourceScopes
} from '@azure/msal-angular';

//local
import { AppConfig } from './app-config';
import { environment } from '../environments/environment';
import { Injectable } from "@angular/core";

export interface ProtectedResources {
    applicationApi:
    {
        endpoint: string;
        scopes: {
            read: string[];
            write: string[];
        };
    };
}

@Injectable()
export class Security
{
    private config: Configuration;

    public SignUpSignInAuthority: string;
    public ResetPasswordAuthority: string;

    constructor(private appConfig: AppConfig, private platform: Platform) { 


        this.SignUpSignInAuthority = `https://${this.appConfig.azureB2c.instance}/${this.appConfig.azureB2c.domain}/${this.appConfig.azureB2c.signUpSignIn}`;
        this.ResetPasswordAuthority = `https://${this.appConfig.azureB2c.instance}/${this.appConfig.azureB2c.domain}/${this.appConfig.azureB2c.resetPassword}`;

        this.config = {
            auth: {
                clientId: this.appConfig.appId, // This is the ONLY mandatory field that you need to supply.

                //b2cPolicies.authorities.signUpSignIn.authority
                // Defaults to "https://login.microsoftonline.com/common"
                authority: this.SignUpSignInAuthority,                
                protocolMode: ProtocolMode.AAD,
                knownAuthorities: [this.appConfig.azureB2c.instance], // Mark your B2C tenant's domain as trusted.
                redirectUri: "/auth", // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
                postLogoutRedirectUri: "/", // Points to window.location.origin by default.        
                navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
            },
            cache: {
                cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
                storeAuthStateInCookie: this.platform.TRIDENT, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
            },
            system: {
                /**
                 * Below you can configure MSAL.js logs. For more information, visit:
                 * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
                 */
                //allowNativeBroker: false, // Disables WAM Broker
                loggerOptions: {
                    loggerCallback(logLevel: LogLevel, message: string) {
                    },
                    logLevel: environment.production ? LogLevel.Warning : LogLevel.Verbose,
                    piiLoggingEnabled: false
                }
            }

        }

    }



    /**
     * Here we pass the configuration parameters to create an MSAL instance.
     * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
     */
    public MSALInstanceFactory(): IPublicClientApplication
    {
        return new PublicClientApplication(this.config);
    }
    

    /**
     * MSAL Angular will automatically retrieve tokens for resources
     * added to protectedResourceMap. For more info, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
     */
    public MSALInterceptorConfigFactory(): MsalInterceptorConfiguration 
    {    
        const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

        let protectedResources = this.getProtectedResources();

        protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
        protectedResourceMap.set(`${this.config.auth.authority}/openid/v2.0/userinfo`, [...protectedResources.applicationApi.scopes.read]);

        protectedResourceMap.set(protectedResources.applicationApi.endpoint,
            [
            {
                httpMethod: 'GET',
                scopes: [...protectedResources.applicationApi.scopes.read]
            },
            {
                httpMethod: 'POST',
                scopes: [...protectedResources.applicationApi.scopes.write]
            },
            {
                httpMethod: 'PUT',
                scopes: [...protectedResources.applicationApi.scopes.write]
            },
            {
                httpMethod: 'DELETE',
                scopes: [...protectedResources.applicationApi.scopes.write]
            },
            {
                httpMethod: 'PATCH',
                scopes: [...protectedResources.applicationApi.scopes.write]
            }
            ]);

        return {
            interactionType: InteractionType.Redirect,
            protectedResourceMap,
        };
    }

    /**
     * Set your default interaction type for MSALGuard here. If you have any
     * additional scopes you want the user to consent upon login, add them here as well.
     */
    public MSALGuardConfigFactory(): MsalGuardConfiguration {
        return {
            interactionType: InteractionType.Redirect,
            authRequest: this.getLoginRequest(),
            //loginFailedRoute: '/login-failed'
        };
    }

    /**
     * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
     */
    public getProtectedResources(): ProtectedResources {
        return {
            applicationApi: {
                endpoint: `${this.appConfig.applicationUri}`,
                scopes: {
                    read: [`https://${this.appConfig.azureB2c.domain}/${this.appConfig.appName}/api`],
                    write: [`https://${this.appConfig.azureB2c.domain}/${this.appConfig.appName}/api`]
                }
            }
        }
    }

    /**
    * Scopes you add here will be prompted for user consent during sign-in.
    * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
    * For more information about OIDC scopes, visit:
    * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
    */
    private getLoginRequest(): { scopes: string[]; } {
        return {
            scopes: [
                "openid",
                "profile",
                "email"
            ]
        };
    }
}