//angular imports
import { Component, OnInit, OnDestroy } from '@angular/core';
//local imports
import { UnityConfig } from '../unity/unity-configuration';
import { LibraryNavigationService } from '../_services/libraryNavigationService';

@Component({
  selector: 'newspace-configurator',
  templateUrl: './newspace-configurator.component.html',
  styleUrls: ['./newspace-configurator.component.css']
})
export class NewSpaceConfiguratorComponent implements OnInit  {

  public unityConfig = new UnityConfig();

  public static dataContext: any = {};
  
  constructor(public libraryNavigationService: LibraryNavigationService) {}

  ngOnInit(): void {    
    this.unityConfig.dataUrl = "/assets/new-space-configurator/Build/new-space-configurator.data.gz";
    this.unityConfig.frameworkUrl = "/assets/new-space-configurator/Build/new-space-configurator.framework.js.gz";
    this.unityConfig.codeUrl = "/assets/new-space-configurator/Build/new-space-configurator.wasm.gz";
    this.unityConfig.streamingAssetsUrl = "/assets/new-space-configurator/StreamingAssets";
    this.unityConfig.productName = "Modutecture Space Configurator";
    this.unityConfig.productVersion = "0.9.1"; 
  }

  public get getDataContext(): any {
    return NewSpaceConfiguratorComponent.dataContext;
  }
}
