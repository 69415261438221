//angular imports
import { Component, OnInit, OnDestroy, Input, SimpleChanges, HostListener } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

//rxjs
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

//msal imports
import { MsalService } from '@azure/msal-angular';

//local imports
import { UserNotificationService } from '../_services/notification.service';
import { AppConfig } from '../app-config';
import { Security } from '../security';
import { UnityConfig } from './unity-configuration';

@Component({
  selector: 'unity',
  templateUrl: './unity.component.html',
  styleUrls: ['./unity.component.css']
})
export class UnityComponent implements OnInit, OnDestroy {

  private _destroying$ = new Subject<void>();

  @Input()
  config: UnityConfig;

  @Input()
  width: string = "1500px";

  @Input()
  height: string = "400px";

  @Input()
  params: UnityParams[];

  @Input()
  showFullscreenButton: boolean = false;

  @Input()
  dataContext: any;

  isLoading: boolean = false;


  constructor(
    private platform: Platform,
    private notificationService: UserNotificationService,
    private authenticationService: MsalService,
    private security: Security,
    private appConfig: AppConfig) {
  }

  ngOnInit() {

    this.isLoading = true;
    
    let unityLogo: HTMLElement = document.querySelector("#unity-webgl-logo");
    unityLogo.style.display = "none";

    let fullscreenButton: HTMLElement = document.querySelector("#unity-fullscreen-button");
    fullscreenButton.style.visibility = "hidden";
    fullscreenButton.style.float = "right";
    fullscreenButton.style.position = "relative";
    fullscreenButton.style.zIndex = "100";
        
    setTimeout(() => this.init(), 500);
  }

  init() {
    console.log('init unity component');

    let canvasPlaceholder: HTMLElement = document.querySelector("#unity-canvas-placeholder");
    
    canvasPlaceholder.style.height = "100%";
    canvasPlaceholder.style.width = "100%";

    if (!this.dataContext.canvas) {
      // UnityComponent a new canvas instance if it doesn't exist
      this.dataContext.canvas = document.createElement('canvas');
      this.dataContext.canvas.setAttribute("id", "unity-canvas");

      canvasPlaceholder.appendChild(this.dataContext.canvas);
    }
    else {
      this.dataContext.canvas.style.display = "block";
      canvasPlaceholder.appendChild(this.dataContext.canvas);
    }

    let container: HTMLElement = document.querySelector("#unity-container");

    container.style.width = this.width;
    container.style.height = this.height;

    let progressBarFull: HTMLElement = document.querySelector("#unity-progress-bar-full");
    let warningBanner: HTMLElement = document.querySelector("#unity-warning");    
    
    this.dataContext.canvas.style.width = "100%"
    this.dataContext.canvas.style.height = "100%"

    const protectedResources = this.security.getProtectedResources();

    const request = {
      scopes: protectedResources.applicationApi.scopes.read,
      account: this.authenticationService.instance.getActiveAccount()
    };

    let authSubscription = this.authenticationService
      .acquireTokenSilent(request)
      .pipe(
        takeUntil(this._destroying$)
      )
      .subscribe({
        next: (response) => {

          authSubscription.unsubscribe();

          if (this.platform.ANDROID || this.platform.IOS) {

            //container.className = "unity-mobile";
            //config.devicePixelRatio = 1;
            warningBanner.style.display = "block";

            setTimeout(() => { warningBanner.style.display = "none"; }, 5000);
          }

          let loadingBar: HTMLElement = document.querySelector("#unity-loading-bar");
          loadingBar.style.display = "none";

          //#region Get refresh token from local storage - TODO replace with a better approach?
          let refreshToken;
          let keys = Object.keys(localStorage);
          let refreshTokenKey = keys.find(key => key.includes('refreshtoken'));

          if (refreshTokenKey) {
            let refreshTokenValue = localStorage.getItem(refreshTokenKey);
            console.log(refreshTokenValue);

            refreshToken = JSON.parse(refreshTokenValue);
          
            if (refreshToken && refreshToken.secret) {
              console.log("Secret found.");
            } else {
              console.log('No secret found in the token');
            }

          } else {
            console.log('No key found that includes "refreshtoken"');
          }
          //#endregion

          if (this.dataContext.unityInstance != undefined) {
            this.dataContext.unityInstance.SendMessage("WebDataTransfer", "SetAccessToken", response.accessToken);
            this.dataContext.unityInstance.SendMessage("WebDataTransfer", "SetRefreshToken", refreshToken.secret);
            this.dataContext.unityInstance.SendMessage("WebDataTransfer", "SetScopes", JSON.stringify(response.scopes));
            this.dataContext.unityInstance.SendMessage("WebDataTransfer", "SetAppConfig", JSON.stringify(this.appConfig));

            if (this.params) {
              this.params.forEach(param => {
                this.dataContext.unityInstance.SendMessage("WebDataTransfer", param.key, param.value);
              });
            }

            let fullscreenButton: HTMLElement = document.querySelector("#unity-fullscreen-button");

            if (this.showFullscreenButton) {
              fullscreenButton.style.visibility = "visible";
            }

            fullscreenButton.onclick = () => {
              this.dataContext.unityInstance.SetFullscreen(1);
            };


            this.isLoading = false;

          }
          else {
            createUnityInstance(this.dataContext.canvas, this.config,
              (progress) => {
                //loadingBar.style.display = "block";
                progressBarFull.style.width = 100 * progress + "%";
              })
              .then((unityInstance) => {
                this.dataContext.unityInstance = unityInstance;
                this.dataContext.unityInstance.SendMessage("WebDataTransfer", "SetAccessToken", response.accessToken);
                this.dataContext.unityInstance.SendMessage("WebDataTransfer", "SetRefreshToken", refreshToken.secret);
                this.dataContext.unityInstance.SendMessage("WebDataTransfer", "SetScopes", JSON.stringify(response.scopes));
                this.dataContext.unityInstance.SendMessage("WebDataTransfer", "SetAppConfig", JSON.stringify(this.appConfig));

                if (this.params) {
                  this.params.forEach(param => {
                    this.dataContext.unityInstance.SendMessage("WebDataTransfer", param.key, param.value);
                  });
                }

                let fullscreenButton: HTMLElement = document.querySelector("#unity-fullscreen-button");

                if (this.showFullscreenButton) {
                  fullscreenButton.style.visibility = "visible";
                }
                
                fullscreenButton.onclick = () => {
                  this.dataContext.unityInstance.SetFullscreen(1);
                };

                loadingBar.style.display = "none";
                this.isLoading = false;
              })
              .catch((message) => {
                this.notificationService.showNotification(message, "error");
                loadingBar.style.display = "none";
                this.isLoading = false;
              });
          }

          
        },
        error: (error) => {
          //toast
          authSubscription.unsubscribe();

          this.notificationService.showNotification(error.error, "error");

          let loadingBar: HTMLElement = document.querySelector("#unity-loading-bar");
          loadingBar.style.display = "none";
          this.isLoading = false;
        }
      })

  }

  //@HostListener('window:beforeunload')
  async ngOnDestroy() {
    this._destroying$.next();

    await this.cleanupUnity();

    this._destroying$.complete();
  }

  private async cleanupUnity() {

    if (!this.dataContext.unityInstance) return;
    if (!this.dataContext.canvas) return;

    let fullscreenButton: HTMLElement = document.querySelector("#unity-fullscreen-button");
    fullscreenButton.remove();

    let container: HTMLElement = document.querySelector("#unity-canvas-placeholder");
    this.dataContext.canvas.style.display = "none";

    container.removeChild(this.dataContext.canvas);

    //this.dataContext.canvas.remove();

    return;



    try {
      await this.dataContext.unityInstance.Quit();
    }
    finally {
      this.dataContext.unityInstance = undefined;
      document.body.removeChild(this.dataContext.canvas);

    }
  }
}

interface UnityParams {
  key: string,
  value: string
}
