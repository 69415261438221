<!-- A single non-reference-type wayfinder tile (home page, reports home etc) -->
<ng-template #nonReferenceTypeWayfinderTile let-tiles>
    <div class="grid-document clickable" *ngFor="let tile of tiles()">
        <div class="grid-thumbnail" (click)="router.navigateByUrl(tile.url)"><div class="full-icon"><fa-icon [icon]="['fas', tile.icon]" size="s"></fa-icon></div></div>
        <div class="grid-document-footer non-reference-type" (click)="router.navigateByUrl(tile.url)">
            <div class="caption center" title="{{ tile.url }}">
                {{ tile.name }}
            </div>
        </div>
    </div>
</ng-template>

<div id="welcome-viewport">
    <!-- Reports header -->
    <div id="reports-header">
        <div class="col-md-12 page-title-box internal-page-title align-middle welcome-breadcrumb">
            <span>Welcome to Modutecture</span>
        </div>
    </div>
    <div class="padded">
        <div class="grid-view-pane">
            <ng-container *ngTemplateOutlet="nonReferenceTypeWayfinderTile; context: {$implicit: tiles}"></ng-container>
        </div>
    </div>
</div>

