import { Component, OnInit } from '@angular/core';


//local imports

import * as appVersion from '../../app.version';
import { AppConfig } from '../../app-config';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  version: string 
  stage: string
  
  constructor(appConfig: AppConfig) { 
    this.stage = appConfig.stage;
    this.version = appVersion.buildVersion;
  }

  ngOnInit(): void {
  }

}
