import { cloneFilter, Filter } from './filter';
import { ReferenceType } from '../_enums/referenceType';
import { expandCamelCase } from '../_helpers/camelCaseHelper';
import { FilterGroup } from '../_interfaces/filterGroup';

export class FiltersSet {
    private groupIndices: number[];
    public groups: FiltersSetGroup[];

    private behaviour: FiltersSetBehaviour;
    public readOnly: boolean = false;

  constructor(filters: Filter[], catGrps: FilterGroup[], behaviour: FiltersSetBehaviour = FiltersSetBehaviour.None) {
        this.behaviour = behaviour;
        this.groups = [];
        this.groupIndices = [];

        catGrps.forEach((catGrp: FilterGroup, index: number) => {
            if (catGrp.filters.length > 0) {
                // index the filters
                let fltrs: Filter[] = [];
                let ind: number[] = [];
                let added: boolean[] = [];
                catGrp.filters.forEach((filter, index) => {
                    fltrs.push(filter);
                    ind[filter.id] = index;
                });
                // build the parent/child structure
                fltrs.forEach((filter) => {
                    if (filter.parentId != null) {
                        var parent = fltrs[ind[filter.parentId]];
                        if (parent != undefined) {
                            if (parent.children === undefined) parent.children = [];
                            parent.children.push(filter);
                        }
                        added[filter.id] = true;
                    }
                });
                // now get only the filters with a null parentId
                let f: Filter[] = [];
                fltrs.forEach((filter) => {
                    if (added[filter.id] === undefined) f.push(filter);
                });
                let fg: FiltersSetGroup = new FiltersSetGroup(ReferenceType.Category);
                fg.name = catGrp.name;
                f.forEach((filter: Filter) => {
                    filter.children.forEach((childFilter: Filter) => {
                        childFilter.indented = true;
                    });
                    fg.addFilter(filter);
                });
                this.groups.push(fg);
            }
        });

        // index the filters
        let filtersIndices: number[] = [];
        filters.forEach((filter, index) => { filtersIndices[filter.referenceType + '.' + filter.id] = index; });

        // build the child/parent relationships
        let childrenAdded: boolean[] = [];
        filters.forEach((filter: Filter, index: number) => {
            let parentIndex = filtersIndices[filter.referenceType + '.' + filter.parentId];
            if (filter.parentId != null && parentIndex !== undefined) {
                let parent = filters[parentIndex];
                filter.indented = true;
                if (parent.children === undefined) parent.children = [];
                parent.children.push(filter);
                childrenAdded[filter.referenceType + '.' + filter.id] = true;
            }
        });

        // remove any children from the root which were added to their parents
        for (let i: number = filters.length - 1; i > -1; i--) {
            if (childrenAdded[filters[i].referenceType + '.' + filters[i].id] !== undefined) {
                filters.splice(i, 1);
            }
        }

        // build the groups
        filters.forEach((filter: Filter, index: number) => {
            if (this.groupIndices[filter.referenceType] === undefined) {
                this.groupIndices[filter.referenceType] = this.groups.length;
                this.groups.push(new FiltersSetGroup(filter.referenceType));
            }
            this.groups[this.groupIndices[filter.referenceType]].addFilter(filter);
        });

        if (this.behaviour == FiltersSetBehaviour.SelectLonelyFilters) {
            this.groups.forEach((group: FiltersSetGroup, referenceType: ReferenceType) => {
                if (group.first3.length == 1 && !group.first3[0].checked) {
                    //          group.setChecked(group.first3[0].id, true, true);
                }
            });
        } else if (this.behaviour == FiltersSetBehaviour.UsesImpliedFilters) {
            this.groups.forEach((filterGroup: FiltersSetGroup, index: number) => {
                switch (filterGroup.first3[0].referenceType) {
                    case ReferenceType.Department:
                    case ReferenceType.Floor:
                    case ReferenceType.ScenarioVariant:
                        //            filterGroup.implied = true;
                        break;
                }
            });
        }
    }

    public search(searchText: string): void {
        let regex: RegExp | null = null;
        if (searchText.length > 0) regex = new RegExp(searchText.toLowerCase().split('*').join('.*'));
        for (let i: number = 0; i < this.groups.length; i++)
            this.groups[i] = this.groups[i].cloneWithFilter(regex);
    }


    disableBehaviour(): void {
        this.behaviour = FiltersSetBehaviour.None;
    }

    findFilter(referenceType: ReferenceType, id: string): Filter {
        let pass: number = 0;
        for (let i: number = 0; i < this.groups.length; i++) {
            let filters = [...this.groups[i].first3, ...this.groups[i].remaining];
            for (let j: number = 0; j < filters.length; j++) {
                if (filters[j].id == id && filters[j].referenceType == referenceType) return filters[j];
            }
        }
        return undefined;
    }

    findGroupForFilter(referenceType: ReferenceType, id: string): FiltersSetGroup {
        let pass: number = 0;
        for (let i: number = 0; i < this.groups.length; i++) {
            let filters = [...this.groups[i].first3, ...this.groups[i].remaining];
            for (let j: number = 0; j < filters.length; j++) if (filters[j].id == id && filters[j].referenceType == referenceType) return this.groups[i];
        }
        return undefined;
    }

    isChecked(referenceType: ReferenceType, id: string): boolean {
        let filter: Filter = this.findFilter(referenceType, id);
        if (filter !== undefined) return filter.checked;
        return false;
    }

  setChecked(referenceType: ReferenceType, id: string, checked: boolean, automatic: boolean): void {
        let fg: FiltersSetGroup = this.findGroupForFilter(referenceType, id);
        if (fg !== undefined) {
            fg.setChecked(id, checked, automatic);
            if (checked && this.behaviour == FiltersSetBehaviour.SelectedJumpToTop) {
                let fullList: Filter[] = [...fg.first3, ...fg.remaining];
                let checked: Filter[] = [];
                let unchecked: Filter[] = [];
                fullList.forEach((filter: Filter, index: number) => {
                    if (filter.checked) checked.push(filter);
                    else unchecked.push(filter);
                });
                fullList = [...checked, ...unchecked];
                fg.first3 = fullList.slice(0, 3);
                fg.remaining = fullList.slice(3);
            }
            if (this.behaviour == FiltersSetBehaviour.SelectedJumpEntireFamilyToTop) {
                fg.selectedJumpToTopIncludeFamily();
            }
        }
    }

    uncheckAll(): void {
        this.groups.forEach((filterGroup: FiltersSetGroup, index: number) => {
            filterGroup.uncheckAll();
        });
    }

    uncheckAllImplied(): void {
        this.groups.forEach((filterGroup: FiltersSetGroup, index: number) => {
            if (filterGroup.implied) filterGroup.uncheckAll();
        });
    }

    uncheckAllAutomatic(): void {
        this.groups.forEach((filterGroup: FiltersSetGroup, index: number) => {
            filterGroup.uncheckAllAutomatic();
        });
    }

    getSelectedFilters(includeAutomaticFilters: boolean = false): Filter[] {
        let f: Filter[] = [];
        this.groups.forEach((group: FiltersSetGroup, index: number) => {
            [...group.first3, ...group.remaining].forEach((filter: Filter, index: number) => {
                if (filter.checked) {
                    let include: boolean = true;
                    if (filter.automatic && !includeAutomaticFilters) include = false;
                    if (include) f.push(filter);
                }
            });
        });
        return f;
    }

    getSelectedFiltersAsString(filters: Filter[] = null, includeAutomatic: boolean): string {
        if (filters == null) filters = this.getSelectedFilters(includeAutomatic);
        let filtersStr = '';
        filters.forEach((filter: Filter, index: number) => {
            filtersStr = filtersStr + (filtersStr.length > 0 ? ',' : '') + filter.referenceType + '.' + filter.id;
        });
        return filtersStr;
    }

    recountAll() {
        this.groups.forEach((group: FiltersSetGroup) => {
            group.countChecked();
        });
    }
}

export enum FiltersSetBehaviour {
    None = 0,
    SelectLonelyFilters = 1,
    UsesImpliedFilters = 2,
    SelectedJumpToTop = 3,
    SelectedJumpEntireFamilyToTop = 4
}

export class FiltersSetGroup {
    name: string = '';
    referenceType: ReferenceType;
    first3: Filter[] = [];
    remaining: Filter[] = [];
    expanded: boolean = false;
    checkCount: number = 0;
    implied = false;
    allFilters: Filter[] = [];
  constructor(referenceType: ReferenceType) {
        this.referenceType = referenceType;
        this.name = expandCamelCase(ReferenceType[this.referenceType]);
    }

    public cloneWithFilter(regex: RegExp | null): FiltersSetGroup {
        let fsg: FiltersSetGroup = new FiltersSetGroup(this.referenceType);
        fsg.first3 = [];
        fsg.remaining = [];
        fsg.expanded = this.expanded;
        fsg.checkCount = 0;
        fsg.implied = false;
        fsg.allFilters = [];
        fsg.name = this.name;

        let checkedLookup: boolean[] = [];
        [...this.first3, ...this.remaining].forEach((filter: Filter) => {
            if (filter.checked) checkedLookup[filter.id] = true;
        });

        this.allFilters.forEach((filter: Filter) => {
            if (checkedLookup[filter.id] !== undefined) filter.checked = true;
            filter.children.forEach((f: Filter) => {
                if (checkedLookup[f.id] !== undefined) f.checked = true;
            });
            fsg.addFilter(cloneFilter(filter), regex);
        });
        return fsg;
    }

    isChecked(id: string): boolean {
        [...this.first3, ...this.remaining].forEach((filter: Filter, index: number) => {
            if (filter.id == id) return filter.checked;
        });
        return false;
    }

    uncheckAllAutomatic() {
        [...this.first3, ...this.remaining].forEach((filter: Filter, index: number) => {
            if (filter.automatic) {
                filter.checked = false;
                filter.automatic = false;
            }
        });
    }

    selectedJumpToTopIncludeFamily(): void {
        let selected: Filter[] = [];
        let unselected: Filter[] = [];
        this.allFilters.forEach((filter: Filter, index: number) => {
            let jump: boolean = false;
            if (filter.checked) jump = true;
            if (!jump) {
                filter.children.forEach((childFilter: Filter, index: number) => {
                    if (childFilter.checked) jump = true;
                });
            }
            if (jump) selected.push(filter);
            else unselected.push(filter);
        });
        this.first3 = [];
        this.remaining = [];
        selected.forEach((filter: Filter, index: number) => {
            if (this.first3.length < 3) this.first3.push(filter);
            else this.remaining.push(filter);
            filter.children.forEach((childFilter: Filter, index: number) => {
                if (this.first3.length < 3) this.first3.push(childFilter);
                else this.remaining.push(childFilter);
            });
        });
        unselected.forEach((filter: Filter, index: number) => {
            if (this.first3.length < 3) this.first3.push(filter);
            else this.remaining.push(filter);
            filter.children.forEach((childFilter: Filter, index: number) => {
                if (this.first3.length < 3) this.first3.push(childFilter);
                else this.remaining.push(childFilter);
            });
        });
    }

    uncheckAll() {
        [...this.first3, ...this.remaining].forEach((filter: Filter, index: number) => { filter.checked = false; });
        this.checkCount = 0;
    }

    addFilter(filter: Filter, regex: RegExp | null = null) {
        if (regex == null || regex.test(filter.displayText.toLowerCase())) {
            if (filter.automatic === undefined) filter.automatic = false;
            if (this.first3.length < 3) this.first3.push(filter);
            else this.remaining.push(filter);
        }
        if (filter.checked) this.checkCount++;

        if (regex == null || (filter.children !== undefined && filter.children.length > 0)) {
            if (filter.children != null) {
                filter.children.forEach((childFilter: Filter, index: number) => {
                    if (regex == null || regex.test(childFilter.displayText.toLowerCase())) {
                        if (this.first3.length < 3) this.first3.push(childFilter);
                        else this.remaining.push(childFilter);
                        if (childFilter.checked) this.checkCount++;
                    }
                });
            }
        }

        filter.children.forEach((childFilter: Filter, index: number) => {
            if (childFilter.checked) this.checkCount++;
        });

        this.allFilters.push(filter);
    }

    countChecked(): void {
        this.checkCount = 0;
        [...this.first3, ...this.remaining].forEach((filter: Filter, index: number) => {
            let include: boolean = false;
            if (filter.checked) this.checkCount++;
            //if (filter.checked) {
            //    if (filter.automatic) {
            //        if (filter.automaticOverridden) include = true;
            //    } else include = true;
            //}
            //if (include) this.checkCount++;
        });
    }

  setChecked(id: string, checked: boolean, automatic: boolean): void {
        let found: boolean = false;
        [...this.first3, ...this.remaining].forEach((filter: Filter, index: number) => {
            if (filter.id == id) {
                filter.checked = checked;
                if (automatic != null && automatic) filter.automatic = true;
            }
        });
        this.countChecked();
    }
}
