<footer class="page-footer">
  
  <div class="px-2 py-2 text-center text-xs text-gray-600 dark:text-gray-300 md:px-[60px]">{{version}} {{stage}}</div>

  <!-- <div class="footer-copyright py-2">

    
    
    © 2022 Copyright :
    <a href="" target="_blank"> Modutecture.com </a>
  </div> -->

</footer>