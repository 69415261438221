export class UnityConfig
 {    
    dataUrl: string;
    frameworkUrl: string;
    codeUrl: string;
    streamingAssetsUrl: string;    
    companyName: string =  "Modutecture";
    productName: string;
    productVersion: string;
    devicePixelRatio = 1;
    //ShowBanner: boolean;
}